//
//
//
//
//
//
//
//
//
//
//

export default {
  data: function () {
    return {
      camiones: []
    }
  },
  created() {
    console.log('create -> [Configuracion]');
  },
  computed: {

  },
  methods: {

  }
};
